







































































































































































import { FormFields, FormFieldType, FormFileType } from '@/includes/types/Form.types'
import { InputSetups } from '@/mixins/input-setups'
import FormFieldWrapper from './FormFieldWrapper.vue'
import { errorNotification } from '@/includes/services/NotificationService'

import { MediaEditorMode } from 'piramis-base-components/src/components/MessageEditorWithMedia/types'
import { UseFields } from 'piramis-base-components/src/components/Pi'
import { PickerFormat } from 'piramis-base-components/src/components/Picker/types'
import { SelectOption } from 'piramis-base-components/src/logic/types'
import { FieldData, SelectOptionData } from 'piramis-base-components/src/components/Pi/types'
import MediaUploader from 'piramis-base-components/src/components/MediaUploader/MediaUploader.vue'
import FilesListRowScroll from 'piramis-base-components/src/components/File/FilesList/FilesListRowScroll.vue'
import { MediaFileData } from 'piramis-base-components/src/components/File/types'

import { Component, Mixins, Prop, VModel, Watch } from 'vue-property-decorator'
import vSelect from 'vue-select'

@Component({
  components: {
    FormFieldWrapper,
    MediaUploader,
    FilesListRowScroll,
    vSelect,
  },
  data() {
    return {
      FormFieldType,
      PickerFormat,
      MediaEditorMode,
      FormFileType
    }
  }
})
export default class FillFormField extends Mixins<UseFields, InputSetups>(UseFields, InputSetups) {
  @VModel() fieldValue!: Array<string> | string | number | null

  @Prop({ type: Object }) readonly config!: FormFields

  @Prop({ type: Boolean }) disabled!: boolean

  @Prop() targetToUpload!:any

  @Watch('localFiles', { deep: true })
  onLocalFilesChange(files):void {

    if (Array.isArray(this.fieldValue) && this.fieldValue.length <= 10) {
      this.fieldValue = files.map(f => f.file)
    }
  }

  localFiles: Array<MediaFileData> = []

  get selectValue(): SelectOptionData | null {
    const optionData = this.fieldConfigOptions.find((o) => o.value === this.fieldValue)

    if (optionData) {
      return optionData
    }
    return null
  }

  set selectValue(value: SelectOptionData | null) {
    this.fieldValue = value ? value.value : value
  }

  get patternCheck():boolean {
    if (this.fieldValue && this.config.type === FormFieldType.String && this.config.validator && this.config.validator.pattern) {
      const { pattern, negate } = this.config.validator

      const regex = new RegExp(pattern)
      const testValueResult = regex.test(this.fieldValue.toString())

      return negate ? !testValueResult : testValueResult
    }

    return false
  }

  handleUpload(file: MediaFileData):void {
    if (this.localFiles.length <= 10) {
      this.localFiles.push(file)
    } else {
      errorNotification('10 files limit')
    }
  }

  removeFile(index):void {
    this.localFiles.splice(index, 1)
  }

  get fieldConfigOptions():Array<SelectOption> {
    if ((this.config.type === FormFieldType.Multiselect || this.config.type === FormFieldType.Select) && this.config.options.length) {

      return this.config.options.slice().map((option, index) => ({
        label: option,
        value: index
      }))
    }

    return []
  }

  setValueIfInLimit(args:FieldData): FieldData | undefined {
    args.setter = (value: any) => {
      if (this.config.type === FormFieldType.Multiselect) {
        let maxLimit = this.config.options.length

        if (!this.config?.max && this.config.limit) {
          maxLimit = this.config.limit
        }
        if (this.config?.max) {
          maxLimit = this.config.max === 0 ? this.config.options.length : this.config.max
        }

        // const maxLimit = this.config.max === 0 ? this.config.options.length : this.config.max

        if (Array.isArray(this.fieldValue) && value.length <= maxLimit! ) {
          this.$set(args.model, args.key, value)
        }
      }
    }

    return args
  }
}
